<template>
  <div class="credentials"  id="Referenzen">
    <div class="centered clearfix">
      <div class="title">
        <h2>Referenzen</h2>
        <div class="feed-link"><span data-mfp-src="#otziv-popup" class="popup-link">Bewertung schreiben</span></div>
        <div class="ref-arrows clearfix">
          <span id="ref-prev-btn" class="prev"></span>
          <span id="ref-next-btn" class="next"></span>
        </div>
      </div>
      <div class="ref-gall">
        <ul class="ref-gall-list clearfix">

          <li class="ref-gall-item">
            <h4>
              Umzug Familie Eim von Wie…            </h4>

            <p>Wir möchten uns bei Ihnen und den Umzugskollegen herzlich bedanken: Es hat alles wunderbar geklappt! Vielen Dank &#038; liebe Grüße Familie Eim ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Umzug Familie Eim von Wiesbaden nach Greifswald am 05.03.2018</h2>
              <p>Wir möchten uns bei Ihnen und den Umzugskollegen herzlich bedanken: Es hat alles wunderbar geklappt!

                Vielen Dank &#038; liebe Grüße
                Familie Eim</p>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Umzug von Mannheim nach H…            </h4>

            <p>Nachdem uns eine andere Umzugsfirma hat sitzenlassen, sind wir auf &#171;Umzug mit Komfort&#187; gestoßen. Wir waren froh, dass alles reibungslos verlaufen ist,obwohl es so kurzfristig ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Umzug von Mannheim nach Heidelberg am 17.02.2018</h2>
              <p>Nachdem uns eine andere Umzugsfirma hat sitzenlassen, sind wir auf &#171;Umzug mit Komfort&#187; gestoßen. Wir waren froh, dass alles  reibungslos verlaufen ist,obwohl es so kurzfristig organisiert wurde. Die Möbelpacker waren gut gelaunt und haben Ihre Arbeit gut gemacht. Wir haben auch keine Schäden an den Möbelstücken feststellen können. Dieses Umzugsunternehmen können wir wärmstens weiterempfehlen.</p>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Umzug November 2016            </h4>

            <p>&#171;Nun ist der Umzugsstress vorbei, deshalb nehmen wir uns jetzt Zeit und möchten uns für den reibungslosen Umzug bedanken. Sie hatten uns eine Tolle Truppe ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Umzug November 2016</h2>
              <p>&#171;Nun ist der Umzugsstress vorbei, deshalb nehmen wir uns jetzt Zeit und möchten uns für den reibungslosen Umzug bedanken.
                Sie hatten uns eine Tolle Truppe geschickt und alles aber alles lief reibungslos. Auch die Demontage und Montage der Möbel sind sehr gut durchgeführt worden
                Wir haben Sie bereits weiter empfohlen und werden falls wir noch mal umziehen sollten auf SIE zurück greifen.
                Ihre Aussage:“ Das Team von Umzug mit Komfort ist ein freundliches, kompetentes und erfahrenes Team. Jeder unterstützt den anderen, denn Umzug heißt Teamwork auf gehobenem Niveau. Dank jahrelanger Erfahrung können wir Ihnen ein Team aus Kraftfahrern, Monteuren und Möbelträgern anbieten, die Sie begeistern werden. “Zitatende
                Trifft voll zu.&#187;<br>
                <br>
                Bruno Crolla aus Offenbach/ November 2016</p>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Wir sind mit            </h4>

            <p>&#171;Umzug mit Komfort&#187; von Hessen nach Niedersachsen gezogen und waren sehr zufrieden. Der Zeitplan wurde perfekt eingehalten, der Umzug wurde schnell umgesetzt und es gab ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Wir sind mit</h2>
              <p>&#171;Umzug mit Komfort&#187; von Hessen nach Niedersachsen gezogen und waren sehr zufrieden. Der Zeitplan wurde perfekt eingehalten, der Umzug wurde schnell umgesetzt und es gab keine Verluste. Sehr zu empfehlen.&#187;<br>
                <br>
                Manuela Berge / September 2015</p>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Klasse Truppe, super Prei…            </h4>

            <p>&#171;Mein nicht ganz einfacher Umzug, bei dem ich einen Wohnsitz (an der Lahn) aufgelöst habe und einen Haupt- (im Hunsrück) und einen 2. Wohnsitz (in ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Klasse Truppe, super Preis!!!</h2>
              <p>&#171;Mein nicht ganz einfacher Umzug, bei dem ich einen Wohnsitz (an der Lahn) aufgelöst habe und einen Haupt- (im Hunsrück) und einen 2. Wohnsitz (in Wiesbaden, 2. Stock / Altbau / Hinterhaus) neu bezogen habe. Es hat alles toll geklappt und das alles in einer Tour von ca. 150 km, von 9:00 Uhr bis 21:00 Uhr.
                Das Team war pünktlich und arbeitete gut organisiert. Der Schreiner &#8212; der Schränke ab und aufgebaut hat &#8212; war super, flink und ein echter Handwerker! Zwar lief es stellenweise etwas schleppend aber im Ergebnis war an einem Tag alles an seinem neuen Platz.
                Das Unternehmen kann ich allen, die Stress beim Gedanken an einen Umzug haben nur empfehlen.&#187;<br>
                <br>
                Simone S./ December 2014</p>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Umzug von Wiesbaden nach …            </h4>

            <p>am 16.09.2013 für die professionelle Arbeit Ihrer Mitarbeiter möchte ich mich bedanken. Sie haben einen sehr guten Preis gemacht und für einen stressfreien Umzug gesorgt. ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Umzug von Wiesbaden nach Mainz</h2>
              <p>am 16.09.2013

                für die professionelle Arbeit Ihrer Mitarbeiter möchte ich mich bedanken. Sie haben einen sehr guten Preis gemacht und für einen stressfreien Umzug gesorgt. Die Mitarbeiter waren pünktlich, freundlich und schnell. Die Möbel wurden zügig ab- und aufgebaut und ordentlich transportiert. Alles hat reibungslos funktioniert. Den nächsten Umzug werde ich gerne wieder bei Ihnen in Auftrag geben.

                &nbsp;

                Mit freundlichen Grüßen

                &nbsp;

                Steffen Antony</p>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Umzug von Mönchengladbach…            </h4>

            <p>Umzug von Mönchengladbach nach Mainz mit Zwischenlagerung vor 2 Jahren. „Dieses Umzugsunternehmen können wir bestens empfehlen. Hier stimmte einfach alles: Pünktlicher Beginn in Mönchengladbach. Alles ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Umzug von Mönchengladbach nach Mainz mit Zwischenlagerung.</h2>
              <p>Umzug von Mönchengladbach nach Mainz mit Zwischenlagerung vor 2 Jahren.

                „Dieses Umzugsunternehmen können wir bestens empfehlen. Hier stimmte einfach alles:

                Pünktlicher Beginn in Mönchengladbach. Alles wurde sicher und ordnungsgemäß

                verpackt, demontiert, etc. und war schneller im LKW als wir gucken konnten. Es kam alles heil an

                und auch die Zwischenlagerung für mehrere Wochen mit anschließender Anlieferung ins

                neue Haus verlief reibungslos. Die Leute sind sehr fleißig und freundlich und der Preis

                stimmt auch – mehr kann man sich von einem Umzugsunternehmen nicht wünschen.

                Jetzt ziehen wir innerhalb von Mainz erneut um und werden natürlich wieder den Service von „Umzug mit Komfort“ nutzen weil`s so schön war J“ Fatima Özkaraaslan-Arzt

                &nbsp;

                Mit herzlichen Grüssen

                &nbsp;

                Fatima Özkaraaslan-Arzt

                Diplom-Psychologin</p>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Wir möchten uns ganz herz…            </h4>

            <p>am 21.09.2013   Es hat wohl alles fantastisch geklappt, herzlichen Dank für den Super-Service &#8212; wir sind begeistert! Gruß Viola Malmbak ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Wir möchten uns ganz herzlich bei Ihnen bedanken.</h2>
              <div style="color: #444444;"><b>am 21.09.2013</b></div>
              <div style="color: #444444;"><b> </b></div>
              <div style="color: #444444;">Es hat wohl alles fantastisch geklappt,</div>
              <div style="color: #444444;">herzlichen Dank für den Super-Service &#8212; wir sind begeistert!</div>
              <div style="color: #444444;">Gruß</div>
              <div style="color: #444444;">Viola Malmbak</div>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Einfach super!            </h4>

            <p>Vorteile: billig, schnell, unkompliziert, höflich Sehr preiswert und kompetent. Was will man mehr? Umzug von: Mainz nach Ladenburg Datum des Umzugs: 05.03.2012 Haushaltsgröße: 3 Personen Umzug ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Einfach super!</h2>
              <div class="reviewTags" style="color: #4c4d4e;">
                <div class="positiveTags"><span style="font-family: Arial, sans-serif;"><b>Vorteile: billig, schnell, unkompliziert, höflich</b></span></div>
                <div class="positiveTags"><span style="color: #444444;"><b>Sehr preiswert und kompetent. Was will man mehr?</b></span></div>
                <br>
              </div>
              <div class="reviewText" style="color: #444444;"><span style="font-family: Arial, sans-serif;"><b style="color: #979797;">Umzug von: Mainz</b><b style="color: #979797;"> nach Ladenburg</b></span></div>
              <div class="reviewText" style="color: #444444;"><b style="color: #979797;">Datum des Umzugs: 05.03.2012</b></div>
              <div class="reviewText" style="color: #444444;"><b style="color: #979797;">Haushaltsgröße: 3 Personen</b></div>
              <div class="reviewText" style="color: #444444;">
                <div style="color: #979797;"><b>Umzug in: Haus</b></div>
                <div style="color: #979797;"><b>Alter: 35-44</b></div>
              </div>
            </div>
          </li>


          <li class="ref-gall-item">
            <h4>
              Nichts auszusetzten - all…            </h4>

            <p>Richard Neumeier, Königstein, vom 30.03.2012 Umzugskartons wurden pünktlich geliefert, Transport verlief zügig und reibungslos, alles Kartons, alle Treppenhäuser heile, freundliche Mitarbeiter &#8212; so sollte es ...</p>

            <div class="more-i"><a href="#" class="ref-link" data-mfp-src=".active .text-popup">Lesen sie mehr</a></div>

            <div class="text-popup popup mfp-hide ref-popup">
              <h2>Nichts auszusetzten &#8212; alles ok</h2>
              <p>Richard Neumeier, Königstein, vom 30.03.2012<br>
                <br>
                Umzugskartons wurden pünktlich geliefert, Transport verlief zügig und reibungslos, alles Kartons, alle Treppenhäuser heile, freundliche Mitarbeiter &#8212; so sollte es sein.</p>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Credentials'
  }
</script>

<style scoped lang="scss">

</style>
