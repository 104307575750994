<template>
  <div class="verp" id="Packmaterial">
    <div class="centered">
      <h2 class="title">Verpackung</h2>
      <div class="box-ico"></div>
      <span class="btn popup-link-2" data-mfp-src="#verp-popup">Preisliste</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Packing'
  }
</script>

<style scoped lang="scss">

</style>
