<template>
  <div class="ask-them" @click="openBlock" :class="{ opened: isOpened }">
    <div class="centered">
      <h2>Ihre nachricht an uns</h2>
      <form action="#" class="ask-them-form" v-validate>
        <div class="fields">
          <input
            required
            type="text"
            name="name"
            class="inpname"
            placeholder="Ihr Name"
            v-model="$store.state.name"
            :disabled="isSending"
          />
          <input
            required
            type="email"
            name="email"
            class="inpmail"
            placeholder="Ihre E-Mail"
            v-model="$store.state.email"
            :disabled="isSending"
          />
          <div class="clear"></div>
          <textarea
            required
            name="message"
            class="otz-area"
            placeholder="Ihre Meinung"
            v-model="message"
            :disabled="isSending"
          ></textarea>
          <div style="text-align: center">
            <input type="submit" value="Senden!" class="sbmt" :disabled="isSending" />
          </div>
        </div>
      </form>
    </div>
    <div class="close-ask" @click.stop.prevent="closeBlock"></div>
  </div>
</template>

<script>
  import 'whatwg-fetch';

  const $ = window.$;

  export default {
    name: 'ContactUs',

    data() {
      return {
        isSending: false,
        isOpened: false,
        message: ''
      }
    },

    mounted() {

    },

    directives: {
      validate: {
        inserted: (el, binding, vnode) => {
          const data = vnode.context;

          $(el).validate({
            errorPlacement: $.noop,
            submitHandler: () => {
              if (process.env.NODE_ENV === 'production') {
                data.isSending = true;

                fetch(require('@/assets/php/feedback.php'), {
                  method: 'POST',
                  body: new FormData(el)
                }).then((res) => {
                  return res.text();
                }).then(res => {
                  if (res === 'ok') {
                    data.openSuccessPopup()
                  } else {
                    alert('(1) Something went wrong! Please contact us: info@angebotumzug.info');
                  }
                }).catch(() => {
                  alert('(2) Something went wrong! Please contact us: info@angebotumzug.info');
                });
              } else {
                data.openSuccessPopup()
              }
              return false;
            }
          });
        }
      }
    },

    methods: {
      openBlock() {
        if (!this.isOpened) {
          this.isOpened = true;
        }
      },
      closeBlock() {
        if (this.isOpened) {
          this.isOpened = false;
        }
      },

      openSuccessPopup() {
        this.isSending = false;
        this.message = '';
        $.magnificPopup.open({
          items: {
            src: '#success-popup'
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .close-ask {
    position: absolute;
    top: 0;
    right: 0;
    background: url("~@/assets/img/close-ask.png") no-repeat center center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s;
    padding: 37px 40px;

    .opened & {
      opacity: 1;
    }
  }
</style>
