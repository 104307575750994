<template>
  <header class="m-header">
    <div class="centered m-header-i">
      <span class="m-menu-tgl"></span>
      <div href="#" class="h-logo">
        <img src="@/assets/img/h-logo.png" alt="">
        <h1 class="visually-hidden">Umzug mit Komfort</h1>
      </div>
      <nav class="navig mobile">
        <ul class="nav-list">
          <li class="nav-item">
            <a class="nav-item-link nl-0" href="#">Up!</a>
          </li>
          <li class="nav-item">
            <a class="nav-item-link nl-1" href="#wir-uber-uns">Wir über uns</a>
          </li>
          <li class="nav-item">
            <a class="nav-item-link nl-2" href="#preise">Preise</a>
          </li>
          <li class="nav-item">
            <a class="nav-item-link nl-3" href="#Packmaterial">Packmaterial</a>
          </li>
          <li class="nav-item">
            <a class="nav-item-link nl-4" href="#Möbellift">Möbellift Vermietung</a>
          </li>
          <li class="nav-item">
            <a class="nav-item-link nl-5" href="#Referenzen">Referenzen</a>
          </li>
          <li class="nav-item">
            <a class="nav-item-link nl-6" href="#AGB">AGB</a>
          </li>
          <li class="nav-item">
            <a class="nav-item-link nl-7" href="#Impressum">Impressum</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'Header'
  }
</script>

<style scoped lang="scss">

</style>
