<template>
  <div class="inv-list-wrap">
    <div class="centered">
      <h2 class="title">Umzugsgutliste</h2>
      <a href="/files/Umzugsliste+Umzug+mit+Komfort.xls" class="btn-xls">Umzugsgutliste</a>
      <div class="clear"></div>
      <div class="inv-list clearfix">
        <p class="inv-list-item">Vergessen Sie bitte nicht, auch den Kleinkram in Keller und Garage einzutragen. Schließlich sollte in der Umzugsgutliste auch wirklich alles stehen, was dann zu transportieren ist.</p>
        <p class="inv-list-item">Der Umrechnungsschlüssel, nach dem Ihr Haushalt dann in Kubikmeter umgerechnet wird, ist seit vielen Jahren erprobt und offiziell anerkannt. So ist ein genaueres Schätzen möglich, als es das bloße Auge erlaubt.</p>
        <p class="inv-list-item">Wir arbeiten mit einem bewährten Hilfsmittel: der so genannten Umzugsgutliste, die alle gängigen Möbelstücke und deren durchschnittlichen Raumbedarf enthält.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Inventory'
  }
</script>

<style scoped lang="scss">

</style>
