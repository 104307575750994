<template>
  <div id="preise-popup" class="popup mfp-hide">
    <p>Unser Aufzug dient Ihnen für erleichtern Ihres Umzuges. Bis zum 250 kg und bis zum 7 Stockwerk mit unseren Bedienpersonal geliefert bis zu Ihrer Haustür.</p>
    <p>1 Stunde – 50,00 EUR, 1/2 Tag – 200,00 EUR, 1 Tag – 350,00 EUR.</p>
    <p>Mindestmietzeit 4 Stunden. Rhein-Main-Gebiet Anlieferung und Abholung inkl. An und Abbau durch unser Personal &#8212; 60,00 EUR:</p>
  </div>
</template>

<script>
  export default {
    name: 'PopupLift'
  }
</script>

<style scoped lang="scss">

</style>
