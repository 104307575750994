<template>
  <div class="lift-rent clearfix" id="Möbellift">
    <div class="left"></div>
    <div class="right">
      <div>

        <h3>MÖBELLIFT MIETEN</h3>
        <p>Unser Aufzug dient Ihnen für erleichtern Ihres Umzuges. Bis zum 250 kg und bis zum 7 Stockwerk mit unseren Bedienpersonal geliefert bis zu Ihrer Haustür. <span class="popup-link-2 pl" data-mfp-src="#preise-popup">Preise ansehen</span></p>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Lift'
  }
</script>

<style scoped lang="scss">

</style>
