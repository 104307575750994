<template>
  <footer class="m-footer" id="Impressum">

    <div class="centered">

      <div class="addr-wrap">
        <div class="logo">
          <img src="@/assets/img/umz_foot-logo.png" alt="">
        </div>
        <div class="addr" style="flex: 1 1 0%;">
          <div style="max-width: 400px; margin: 0 auto;">
            Inhaber: Tetyana Kigel<br/>
            Peter-sander-str. 35-39 55252 Mainz-Kastel<br/>
            <a href="mailto:info@angebotumzug.info">info@angebotumzug.info</a> / <a href="mailto:info@umzug-rhein-main.de">info@umzug-rhein-main.de</a>
          </div>
        </div>
        <div class="tel">
          <div class="phone">06131 6082127</div>
          <div class="fax"><span>Fax:</span>06131 4805092</div>
        </div>
      </div>

      <div class="clear"></div>
      <div class="bottom clearfix">
        <div class="copy">2018. Umzug Mit Komfort.</div>
        <!--<a href="http://trickynow.com/" target="_blank" class="tested-company tested-company&#45;&#45;footer">
          <img src="@/assets/img/tested.png" alt="">
        </a>-->
        <div class="developers">
          Entwickelt von: <a href="https://heartbeat.ua" class="hb" target="_blank"></a>
        </div>
        <p>Die Vervielfältigung von Informationen von unserer Website ist ausschließlich im Einvernehmen mit der Verwaltung der Website.</p>
      </div>
    </div>
  </footer>
  <!--<a href="http://trickynow.com/" target="_blank" class="tested-company tested-company&#45;&#45;header">
    <img src="@/assets/img/tested.png" alt="">
  </a>-->
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>

<style scoped lang="scss">

</style>
