<template>
  <div class="imprint hidden" id="AGB">
    <div class="centered">
      <h2><span>AGB</span> - Allgemeine Geschäftsbedingungen Umzug mit Komfort</h2>
      <p class="subtitle">Für die Geschäftsbeziehung gelten ausschließlich die nachfolgenden Allgemeinen Geschäftsbedingungen. Davon abweichende Bedingungen erkennt Umzug mit Komfort nur an, sofern Sie ausdrücklich schriftlich vereinbart worden sind.</p>
      <ul class="impr-list">

        <li class="impr-item">
          <h3>1. LEISTUNGEN UND ENTGELT</h3>
          <p>Der Möbelspediteur führt unter Wahrung des Kundeninteresses seine Verpflichtung mit der verkehrsüblichen Sorgfalt eines ordentlichen Möbelspediteurs aus. Zahlung des vereinbarten Entgelts erfolgt unmittelbar nach Beendigung der Leistung. Zusätzlich zu vergüten sind besondere, bei Vertragsabschluß nicht vorhersehbare Leistungen und Aufwendungen. Gleiches gilt, wenn der Leistungsumfang durch den Kunden nach Vertragsabschluß erweitert wird.</p>
        </li>


        <li class="impr-item">
          <h3>2. TRANSPORTSICHERUNG</h3>
          <p>Der Kunde ist verpflichtet, bewegliche oder elektronische Teile an hochempfindlichen Geräten wie z.B. Waschmaschinen, Plattenspielern, Fernseh-, Radio- und HiFi Geräten, EDV-Anlagen fachgerecht für den Transport zu sichern. Zur Überprüfung der fachgerechten Transportsicherung ist der Möbelspediteur nicht verpflichtet. Der Möbelspediteur haftet nicht für elektronische Schäden die insbesondere bei älteren Geräten außerhalb der Garantiezeit durch den sachgemäßen Transport evtl. entstehen könnten.</p>
        </li>


        <li class="impr-item">
          <h3>3. TRANSPORTRECHTSÄNDERUNGSGESETZ UND BETRIEBSHAFTPFLICHT</h3>
          <p>Wir fahren pflichtversichert nach TRG und sind zusätzlich bei der Helvetia Versicherung mit einer Betriebshaftpflicht von 3,0 Mio. EUR für das Umzugsgut versichert.</p>
        </li>


        <li class="impr-item">
          <h3>4. ELEKTRO- UND INSTALLATIONSARBEITEN</h3>
          <p>Die Mitarbeiter des Möbelspediteurs sind, sofern nichts anderes vereinbart ist, nicht zur Vornahme von Elektro-, Gas- und sonstigen Installationsarbeiten berechtigt.</p>
        </li>


        <li class="impr-item">
          <h3>5. HANDWERKERVERMITTLUNG</h3>
          <p>Bei Leistungen von zusätzlich vermittelten Handwerkern übernimmt der Möbelspediteur keine Haftung.</p>
        </li>


        <li class="impr-item">
          <h3>6. AUFRECHNUNG</h3>
          <p>Gegen Ansprüche des Möbelspediteurs ist eine Aufrechnung nur mit fälligen Gegenansprüchen zulässig, die rechtskräftig festgestellt oder unbestritten sind.</p>
        </li>


        <li class="impr-item">
          <h3>7. NACHPRÜFUNG DURCH DEN KUNDEN</h3>
          <p>Nach dem Entladen des Umzugsgutes ist der Kunde zur Nachprüfung verpflichtet, so dass kein Gegenstand versehentlich mitgenommen oder stehengelassen wird.</p>
        </li>


        <li class="impr-item">
          <h3>8. FÄLLIGKEIT DES VEREINBARTEN ENTGELTS</h3>
          <p>Der Rechnungsbetrag ist bei Inlandstransporten nach Beendigung der Entladung, bei Auslandstransporten vor Beginn der Verladung fällig und in bar oder in Form gleichwertiger Zahlungsmittel zu bezahlen. Barauslagen in ausländischer Währung sind nach dem abgerechneten Wechselkurs zu entrichten. Kommt der Absender seiner Zahlungspflicht nicht nach, ist der Möbelspediteur berechtigt, das Umzugsgut einzubehalten oder auf Kosten des Kunden einzulagern.</p>
        </li>


        <li class="impr-item">
          <h3>9. GERICHTSSTAND</h3>
          <p>Für Rechtsstreitigkeit mit Vollkaufleuten auf Grund dieses Vertrages und über Ansprüche aus anderen Rechtsgründen, die mit dem Transportvertrag zusammenhängen, ist das Gericht Mainz, ausschließlich zuständig.</p>
        </li>


        <li class="impr-item">
          <h3>10. Transportschäden</h3>
          <p>Untersuchen Sie das Umzugsgut sofort bei Ablieferung auf offensichtliche Beschädigung oder Verlust. Äußerlich erkennbare Schäden müssen sofort, verdeckte Schäden müssen innerhalb von 14 Tagen schriftlich gemeldet werden. Pauschale Hinweise genügen nicht. Spätere Reklamationen können wir und unsere Versicherung leider aus rechtlichen Gründen nicht anerkennen.</p>
        </li>


        <li class="impr-item">
          <h3>11. Rechtswahl</h3>
          <p>Es gilt ausschließlich deutsches Recht.</p>
        </li>

      </ul>
      <div class="imprint-show-more"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Agb'
  }
</script>

<style scoped lang="scss">

</style>
