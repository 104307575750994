<template>
  <footer class="slider-foot" style="display: none;">
    <div class="centered">
      <div class="slide-txt">
        <p>Achtung: neue Festnetz Nummer ab dem 14.02.2018: 06131&nbsp;6082127</p>
      </div>
      <a class="w-btn nl-2" href="#preise">Bestellen</a>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Info'
  }
</script>

<style scoped lang="scss">

</style>
