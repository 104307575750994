<template>
  <div id="app">
    <div class="page">
      <Header></Header>
      <Screen></Screen>
      <Info></Info>
      <AboutUs></AboutUs>
      <Prices></Prices>
      <Packing></Packing>
      <Lift></Lift>
      <Credentials></Credentials>
      <ContactUs></ContactUs>
      <Inventory></Inventory>
      <Agb></Agb>
      <div class="order-btn">
        <div class="centered">
          <span class="btn" @click="openPopup('l')">Bestellen!</span>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <PopupFeedback></PopupFeedback>
    <PopupPackage></PopupPackage>
    <PopupPacking></PopupPacking>
    <PopupLift></PopupLift>
    <PopupSuccess></PopupSuccess>
  </div>
</template>

<script>
  import FontFaceObserver from 'fontfaceobserver'
  import AboutUs from './components/AboutUs.vue'
  import Prices from './components/Prices.vue'
  import Packing from './components/Packing.vue'
  import Lift from './components/Lift.vue'
  import Credentials from './components/Credentials.vue'
  import ContactUs from './components/ContactUs.vue'
  import Inventory from './components/Inventory.vue'
  import Agb from './components/Agb.vue'
  import Footer from './components/Footer.vue'
  import Header from './components/Header.vue'
  import Info from './components/Info.vue'
  import Screen from './components/Screen.vue'
  import PopupFeedback from './components/Popup/Feedback.vue'
  import PopupPackage from './components/Popup/Package.vue'
  import PopupPacking from './components/Popup/Packing.vue'
  import PopupLift from './components/Popup/Lift.vue'
  import PopupSuccess from './components/Popup/Success.vue'

  import '@/assets/js/main.js'

  const $ = window.$;

  export default {
    name: 'app',

    components: {
      AboutUs,
      Prices,
      Packing,
      Lift,
      Credentials,
      ContactUs,
      Inventory,
      Agb,
      Footer,
      Header,
      Info,
      Screen,
      PopupFeedback,
      PopupPackage,
      PopupPacking,
      PopupLift,
      PopupSuccess
    },

    mounted() {
      window.hbScripts();

      const fontAdam = new FontFaceObserver('Adam');
      const fontProxima = new FontFaceObserver('Proxima Nova');

      Promise.all([fontAdam.load(), fontProxima.load()]).then(
        () => this.hideLoader(),
        () => this.hideLoader()
      );
    },

    methods: {
      hideLoader() {
        $('#loader').hide();
        $('html, body').css('overflow', '');
      },
      openPopup(packet) {
        const s = this.$store;
        s.commit('updatePacket', packet || s.state.packet);
        $.magnificPopup.open({
          items: {
            src: '#packet-popup',
            type: 'inline'
          }
        });
      }
    }

  }
</script>

<style src="@/assets/scss/styles.scss" lang="scss"></style>
<style src="@/assets/css/style.css"></style>
