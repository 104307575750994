<template>
  <div id="packet-popup" class="popup mfp-hide">
    <h2>Ihre Bestellung</h2>
    <form action="#" class="form" v-validate>
      <select
        name="packet"
        class="sel"
        v-model="$store.state.packet"
        :disabled="isSending"
      >
        <option :value="'s'">Paket S</option>
        <option :value="'m'">Paket M</option>
        <option :value="'l'">Paket L</option>
        <option :value="'xl'">Paket XL</option>
      </select>
      <input
        required
        type="text"
        name="name"
        class="inp-text"
        placeholder="Ihr Name"
        v-model="$store.state.name"
        :disabled="isSending"
      />
      <input
        type="email"
        name="email"
        class="inp-text"
        placeholder="Ihre E-mail"
        v-model="$store.state.email"
        :disabled="isSending"
      />
      <input
        required
        type="tel"
        name="tel"
        class="inp-text"
        placeholder="Ihr Telefon"
        v-model="$store.state.tel"
        :disabled="isSending"
      />
      <input type="submit" value="Jetzt absenden" class="btn-sbmt" :disabled="isSending" />
    </form>
  </div>
</template>

<script>
  import 'whatwg-fetch';

  const $ = window.$;

  export default {
    name: 'PopupPackage',

    data() {
      return {
        isSending: false
      }
    },

    mounted() {

    },

    directives: {
      validate: {
        inserted: (el, binding, vnode) => {
          const data = vnode.context;

          $(el).validate({
            errorPlacement: $.noop,
            submitHandler: () => {
              if (process.env.NODE_ENV === 'production') {
                data.isSending = true;

                fetch(require('@/assets/php/packet.php'), {
                  method: 'POST',
                  body: new FormData(el)
                }).then((res) => {
                  return res.text();
                }).then(res => {
                  if (res === 'ok') {
                    data.openSuccessPopup()
                  } else {
                    alert('(1) Something went wrong! Please contact us: info@angebotumzug.info');
                  }
                }).catch(() => {
                  alert('(2) Something went wrong! Please contact us: info@angebotumzug.info');
                });
              } else {
                data.openSuccessPopup()
              }
              return false;
            }
          });
        }
      }
    },

    methods: {
      openSuccessPopup() {
        this.isSending = false;
        $.magnificPopup.open({
          items: {
            src: '#success-popup'
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
