<template>
  <div class="options fixed" id="preise">
    <div class="centered">
      <div class="title">
        <h2>Wählen Sie die beste Option</h2>
      </div>
      <div class="packet-arrows clearfix">
        <span id="packet-prev-btn" class="prev"></span>
        <span id="packet-next-btn" class="next"></span>
      </div>
      <div class="packets-wrap">
        <ul class="packets clearfix">
          <li class="packet">
            <span class="pack-size s-ico"></span>
            <p>Beladen und fachgerechte Sicherung des Umzugsgutes mittels LKW, Transport, Entladen an Bestimmungsort.</p>
            <ul>
<!--              <li>— 1 Stunde – 110,00 EUR;</li>-->
              <li>— 1/2 Tag – 470,00 EUR;</li>
              <li>— 1 Tag – 900,00 EUR.</li>
            </ul>
            <span @click="openPopup('s')" class="btn">Bestellen</span>
          </li>
          <li class="packet">
            <span class="pack-size s-ico"></span>
            <p>S + Ab und Aufbau von Möbel durch erfahrene Schreiner inkl. Verpackungsmaterial für Möbel.</p>
            <ul>
<!--              <li>— 1 Stunde – 140,00 EUR;</li>-->
              <li>— 1/2 Tag – 600,00 EUR;</li>
              <li>— 1 Tag – 1100,00 EUR.</li>
            </ul>
            <span @click="openPopup('m')" class="btn">Bestellen</span>
          </li>
          <li class="packet">
            <span class="pack-size s-ico"></span>
            <p>M + Einpackservice inkl. 50 Umzugskartons und 4 Kleiderhängerkartons.</p>
            <ul>
<!--              <li>— 1 Stunde – 160,00 EUR;</li>-->
              <li>— 1/2 Tag – 680,00 EUR;</li>
              <li>— 1 Tag – 1200,00 EUR.</li>
            </ul>
            <span @click="openPopup('l')" class="btn">Bestellen</span>
          </li>
          <li class="packet">
            <span class="pack-size s-ico"></span>
            <p>Komplettpacket “ Umzug mit Komfort” inkl. Ein — sowie Auspacken von Umzugsgut und Verpackungsmaterial.</p>
            <ul>
<!--              <li>— 1 Stunde – 180,00 EUR;</li>-->
              <li>— 1/2 Tag – 700,00 EUR;</li>
              <li>— 1 Tag – 1300,00 EUR.</li>
            </ul>
            <span @click="openPopup('xl')" class="btn">Bestellen</span>
          </li>
        </ul>
      </div>
      <footer class="opt-foot clearfix">
        <div class="info">
          <p>Alle Preise inkl. 19% MwSt und Haftpflichtversicherung,<br>
            Anfahrtskosten in Rhein-Main Gebiet – 30,00 EUR.<br>
            1/2 Tag – 4 Stunden, 1 Tag – 8 Stunden</p>
        </div>
        <ul class="list">
          <li>- kostenlose Besichtigung</li>
          <li>- kein Wochenendzuschlag</li>
          <li>- Festpreise nach Absprache</li>
          <li>- Umzüge Europaweit</li>
          <li>- Pack- & Montagearbeiten</li>
          <li>- Küchenmontagen</li>
        </ul>
        <ul class="list">
          <li>- Wohnungsentrümpelung</li>
          <li>- Renovierungsarbeiten</li>
          <li>- Endreinigung</li>
          <li>- Sozial- und Arbeitsamt Direcktabrechnung</li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
  const $ = window.$;

  export default {
    name: 'Prices',

    data() {
      return {

      }
    },

    mounted() {

    },

    methods: {
      openPopup(packet) {
        this.$store.commit('updatePacket', packet);
        $.magnificPopup.open({
          items: {
            src: '#packet-popup',
            type: 'inline'
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
