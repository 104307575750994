window.hbScripts = function () {
  var $ = window.$;

  function floatNav(e, t) {
    var n = e.scrollTop();
    if (n >= 100 && !t.hasClass("fixed")) {
      t.addClass("fixed")
    }
    if (n < 37 && t.hasClass("fixed")) {
      t.removeClass("fixed")
    }
  }

  var isWebkit = "WebkitAppearance" in document.documentElement.style;

  if (isWebkit) {
    $("html").addClass("webkit")
  }

  $("a[href^=#]").on("click", function(e) {
    e.preventDefault();
    history.pushState({}, "", this.href)
  });
  var e = $(window),
    t = $(".navig"),
    o = $("html, body");
  floatNav(e, t);

  window.addEventListener("scroll", function() {
    var o = e.scrollTop();
    floatNav(e, t);

    if (o > $(".about").position().top - 50 && o <= $(".options").position().top - 50) {
      $(".nav-item-link").removeClass("active");
      $(".nl-1").addClass("active")
    } else if (o > $(".options").position().top - 50 && o <= $(".verp").position().top - 50) {
      $(".nav-item-link").removeClass("active");
      $(".nl-2").addClass("active")
    } else if (o > $(".verp").position().top - 50 && o <= $(".lift-rent").position().top - 50) {
      $(".nav-item-link").removeClass("active");
      $(".nl-3").addClass("active")
    } else if (o > $(".lift-rent").position().top - 50 && o <= $(".credentials").position().top - 50) {
      $(".nav-item-link").removeClass("active");
      $(".nl-4").addClass("active")
    } else if (o > $(".credentials").position().top - 50 && o <= $(".imprint").position().top - 50) {
      $(".nav-item-link").removeClass("active");
      $(".nl-5").addClass("active")
    } else if (o > $(".imprint").position().top - 50 && o < $(".m-footer").position().top - 50) {
      $(".nav-item-link").removeClass("active");
      $(".nl-6").addClass("active");
      if (o >= $(document).height() - e.height()) {
        $(".nav-item-link").removeClass("active");
        $(".nl-7").addClass("active")
      }
    } else $(".nav-item-link").removeClass("active")
  }, false);

  var u = [".about", ".options", ".verp", ".lift-rent", ".credentials", ".imprint", ".m-footer"];

  for (var a = 0; a < u.length; a++) {
    (function(e) {
      $(".nl-" + (a + 1)).on("click", function() {
        var t = $(u[e]).position().top;
        o.animate({
          scrollTop: t - 49
        }, 400)
      })
    })(a)
  }

  $(".nl-0").on("click", function() {
    o.animate({
      scrollTop: 0
    }, 400)
  });

  $(".ref-link").on("click", function() {
    $(this).closest("li").addClass("active").siblings().removeClass("active")
  });

  $(".ref-gall").sly({
    horizontal: 1,
    touchDragging: 1,
    itemNav: "basic",
    speed: 400,
    releaseSwing: .5,
    prevPage: $("#ref-prev-btn"),
    nextPage: $("#ref-next-btn")
  });

  $(".imprint-show-more").on("click", function() {
    if ($(this).closest(".hidden").length) {
      $(".imprint").removeClass("hidden")
    } else {
      $(".imprint").addClass("hidden")
    }
  });

  $(".touch .packets-wrap").sly({
    horizontal: 1,
    itemNav: "basic",
    speed: 400,
    mouseDragging: 1,
    touchDragging: .5,
    releaseSwing: 1,
    prevPage: $("#packet-prev-btn"),
    nextPage: $("#packet-next-btn")
  });

  $(".popup-link, .popup-link-2, .ref-link").magnificPopup();

  $("[data-mfp-src]").on("mfpBeforeOpen", function() {
    $("html").addClass("mfp-opened")
  }).on("mfpClose", function() {
    $("html").removeClass("mfp-opened")
  });

  $(".m-menu-tgl").on("click", function() {
    $(".navig.mobile").toggleClass("active")
  })
};
