import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packet: 'l',
    name: '',
    email: '',
    tel: ''
  },
  mutations: {
    updatePacket(state, n) {
      state.packet = n;
    }
  }
});
