<template>
  <div class="about" id="wir-uber-uns">
    <div class="centered clearfix">
      <h2>Wir über uns</h2>
      <div class="four-boxes clearfix">
        <div class="about-box box_1">Wir sind ein Familien Full-Service-Umzugsunternehmen, das Sie kompetent und flexibel bei allen notwendigen Schritten fürUmzügebegleitet.</div>
        <div class="about-box box_2">Aufgrund unserer langfristigen Erfahrung wissen wir, dass jeder Umzug immer einen Schritt in einen neuen Lebensabschnitt bedeutet.</div>
        <div class="about-box box_3">Wir sind nicht nur Experten für Ihren Umzug, sondern beraten Sie auch gerne kostenlos vorab, wie Sie Ihren Umzugsablauf stressfrei durchführen können.</div>
        <div class="about-box box_4">Machen Sie noch heute einen kostenlosen und unverbindlichen Besichtigungstermin , damit wir Ihnen ein Festpreisangebot zuschicken können.</div>
      </div>
      <p class="b-text">Das Team von Umzug mit Komfort ist ein freundliches, kompetentes und erfahrenes Team. Jeder unterstützt den anderen, denn Umzug heißt Teamwork auf gehobenen Niveau. Dank jahrelanger Erfahrung können wir Ihnen ein Team aus Kraftfahrern, Monteuren und Möbelträgern anbieten, die Sie begeistern werden.</p>
      <div class="c-box">
        <p>Informationen über unseren Service und unsere Produkte finden Sie hier.</p>
        <span class="btn nl-2">Lesen Sie mehr!</span>
      </div>
      <div class="c-box">
        <p>Kontaktieren Sie unseren Vertrieb, um mehr Informationen zu erhalten.</p>
        <span class="btn nl-7">Lesen Sie mehr!</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AboutUs'
  }
</script>

<style scoped lang="scss">

</style>
