<template>
  <div id="success-popup" class="popup mfp-hide" style="max-width: 555px;">
    <div class="f-sent-popup">
      <h2>Vielen Dank für <br> Ihre Anfrage.</h2>
      <p>Sie werden in Kürze kontaktiert.</p>
      <div class="line"></div>
      <div class="pop-close-btn" @click="closePopup">schliessen</div>
    </div>
  </div>
</template>

<script>
  const $ = window.jQuery;

  export default {
    name: 'PopupSuccess',

    methods: {
      closePopup() {
        $.magnificPopup.close()
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
