<template>
  <div id="verp-popup" class="popup mfp-hide">
    <h2>Verpackung</h2>
    <div class="col col-1">
      <table>
        <tr>
          <th>Kauf:</th>
          <th>Netto / Brutto</th>
        </tr>
        <tr>
          <td>Umzugskartons (Umzugskartons Profiqualität)</td>
          <td>€ 2,80 / € 3,33</td>
        </tr>
        <tr>
          <td>Kleiderboxen</td>
          <td>€ 10,00 / € 11,90</td>
        </tr>
        <tr>
          <td>Luftpolsterfolie 1m</td>
          <td>€ 1,00 / € 1,19</td>
        </tr>
      </table>
    </div>
    <div class="col col-2">
      <table>
        <tr>
          <th>Kauf:</th>
          <th>Netto / Brutto</th>
        </tr>
        <tr>
          <td>Packpapier (1kg)</td>
          <td>€ 2,00 / € 2,38</td>
        </tr>
        <tr>
          <td>Umzugsdecken</td>
          <td>€ 5,50 / € 6,55</td>
        </tr>
      </table>
    </div>
    <div class="clear"></div>
    <h3>Miete</h3>
    <p>Natürlich haben wir auch Umzugsmaterial, welches Sie für verschiedene Zeiträume bei uns mieten können. Hier eine kleine Übersicht:</p>
    <table class="table-wide">
      <tr>
        <th>Bezeichnung</th>
        <th>Preis</th>
        <th>Kaution</th>
        <th>Zeitraum</th>
      </tr>
      <tr>
        <td>Umzugskarton</td>
        <td>2,80 € / Stk.</td>
        <td>0,00 € / Stk.</td>
        <td>4 Wochen</td>
      </tr>
      <tr>
        <td>Kleiderkiste</td>
        <td>4,00 € / Stk.</td>
        <td>0,00 € / Stk.</td>
        <td>Leihpreis am Umzugstag</td>
      </tr>
    </table>
    <div class="line"></div>
  </div>
</template>

<script>
  export default {
    name: 'PopupPacking'
  }
</script>

<style scoped lang="scss">

</style>
