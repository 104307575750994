<template>
  <div class="slider">
    <div class="gallery" id="gal">
      <div class="slide active">
        <div class="centered" style="">
          <div class="hero-text-box">
            <h2 class="hero-text-title">Aktion!</h2>
            <p class="hero-text-par">
              5 Kleiderkartons zu jedem Paket zur Miete gratis
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Screen'
  }
</script>

<style scoped lang="scss">

</style>
